import React from "react"
import PropTypes from "prop-types"
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFacebook, faTwitter, faDailymotion, faYoutube } from '@fortawesome/free-brands-svg-icons'

import { Navbar } from "../../components"

import "../../styles/styles.scss"

library.add(faFacebook, faDailymotion, faTwitter, faYoutube)

const Layout = ({ children }) => {
  return (
    <>
        <Navbar />
        <main>{children}</main>
        <footer className="footer has-background-black">
          <div className="container is-size-7">
            © {new Date().getFullYear()} Future Artists Ltd. All Rights Reserved. Reproduction without explicit permission is prohibited.
          </div>
        </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
