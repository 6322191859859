import React from "react"
import PropTypes from 'prop-types'
import { Link } from "gatsby"
import Img from "gatsby-image"

import { PostDate } from "../"

const Preview = (props) => {
  const featuredImage = props.post.featured_media && props.post.featured_media.localFile ? (
     <Img fluid={ props.post.featured_media.localFile.childImageSharp.fluid }  />
  ) : ('')

  return (
      <article className="column is-6-desktop">
        <div className="card">
          <div className="card-image">
            <figure className="image is-4by3">
            <Link to={`/${props.post.slug}`}>
                {featuredImage}
            </Link>
            </figure>
          </div>
          <div className="card-content">
            <div className="media">
              <div className="media-content">
                <Link to={`/${props.post.slug}`}><h2 className="title is-4" dangerouslySetInnerHTML={{ __html: props.post.title }} /></Link>
                <PostDate date={new Date(props.post.date)} />
              </div>
            </div>
            <div className="content" dangerouslySetInnerHTML={{ __html: props.post.excerpt }} />
          </div>
        </div>      
      </article>
  )
}

Preview.propTypes = {
  post: PropTypes.object.isRequired
}
  
export default Preview;