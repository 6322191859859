import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import styles from "./Sidebar.module.scss"

const Sidebar = () => {
  const data = useStaticQuery(graphql`
    query {
      portalImage: file(relativePath: { eq: "portalbutton.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      iantiltonImage: file(relativePath: { eq: "iantilton.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      youtubeImage: file(relativePath: { eq: "youtube.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      instagramImage: file(relativePath: { eq: "instagram.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)
  return (
      <aside className={"content " + styles.sidebar}>
        <a href="https://www.youtube.com/watch?v=4oGSRHngSu8&t=3s" target="_blank" rel="noreferrer"><Img className={styles.sidebarimage} fluid={data.portalImage.childImageSharp.fluid} alt="Watch Portal" /></a>
        <p><a href=" https://www.youtube.com/watch?v=4oGSRHngSu8&t=3s" target="_blank" rel="noreferrer">Award winning and critically acclaimed web series Portal now on Youtube - Produced by Future Artists</a></p>

        <a href="https://www.etsy.com/shop/FutureartistsStudio" target="_blank" rel="noreferrer"><Img className={styles.sidebarimage} fluid={data.iantiltonImage.childImageSharp.fluid} alt="Ian Tilton Art" /></a>
        <p><a href="https://www.etsy.com/shop/FutureartistsStudio" target="_blank" rel="noreferrer">Visit our shop and see our artists</a></p>

        <a href="https://www.youtube.com/channel/UCbMG4Hz7Tq1ZPV7nH2EUCqQ" target="_blank" rel="noreferrer"><Img className={styles.sidebarimage} fluid={data.youtubeImage.childImageSharp.fluid} alt="Watch Portal" /></a>
        <p><a href="https://www.youtube.com/channel/UCbMG4Hz7Tq1ZPV7nH2EUCqQ" target="_blank" rel="noreferrer">Watch us on Youtube and Subscribe</a></p>

        <a href="https://www.instagram.com/future_artists/" target="_blank" rel="noreferrer"><Img className={styles.sidebarimage} fluid={data.instagramImage.childImageSharp.fluid} alt="Watch Portal" /></a>
        <p><a href="https://www.instagram.com/future_artists/" target="_blank" rel="noreferrer">Hang out with us on Instagram</a></p>
      </aside>
  )
}
  
export default Sidebar;