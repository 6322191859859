import React from "react"
import BackgroundImage from 'gatsby-background-image'
import PropTypes from 'prop-types'
import { Link } from "gatsby"

const Hero = (props) => {
  if(props.image) {
    return (
    <BackgroundImage
      Tag="section"
      className="hero is-info is-medium"
      fluid={props.image}>
      <div className="hero-body">
        <div className="container">
          { props.title &&
            <h1 className="title" dangerouslySetInnerHTML={{ __html: props.title }} />
          }
          { props.excerpt &&
          <h2 className="subtitle" dangerouslySetInnerHTML={{ __html: props.excerpt }} />
          }
          { props.content &&
            props.content
          }
          { props.link && props.button &&
          <Link to={`/${props.link}`} className="button is-primary">{ props.button }</Link>
          }
        </div>
      </div>
    </BackgroundImage>
    )
  } else {
    return (
    <section className="hero is-blue">
      <div className="hero-body">
        <div className="container has-text-white">
          { props.title &&
            <h1 className="title has-text-white" dangerouslySetInnerHTML={{ __html: props.title }} />
          }
          { props.excerpt &&
          <h2 className="subtitle" dangerouslySetInnerHTML={{ __html: props.excerpt }} />
          }
          { props.content &&
            props.content
          }
        </div>
      </div>
    </section>
    )
  }
}

Hero.propTypes = {
  title: PropTypes.string,
  excerpt: PropTypes.string,
  link: PropTypes.string,
  button: PropTypes.string,
  image: PropTypes.object,
  content: PropTypes.object
}
  
export default Hero;