import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "gatsby"

import Logo from "../../images/futureartists.svg"

import "./Navbar.scss"

const NavbarBurger = props => (
  <button onClick={props.toggleMenu} className={`navbar-burger burger ${props.active ? 'is-active' : ''}`} aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
    <span aria-hidden="true"></span>
    <span aria-hidden="true"></span>
    <span aria-hidden="true"></span>
  </button>
);

class Navbar extends React.Component {
    state = {
      activeMenu: false,
    };
    toggleMenu = () => {
      this.setState({
        activeMenu: !this.state.activeMenu,
      });
    };
    render() {
      return (
        <nav className="navbar" role="navigation" aria-label="main navigation">
            <div className="navbar-brand">
              <Link to="/" className="navbar-item">
                <img src={Logo} alt="Future Artists" />
              </Link>
              <NavbarBurger
                active={this.state.activeMenu}
                toggleMenu={this.toggleMenu}
              />
            </div>
  
            <div id="navbarBasicExample" className={`navbar-menu ${this.state.activeMenu ? 'is-active' : ''}`}>
              <div className="navbar-start">
                <Link to="/about" className="navbar-item">
                  About Us
                </Link>
  
                <a href="/category/blog" className="navbar-item">
                  Blog
                </a>
  
                <a href="/category/press" className="navbar-item">
                  Press
                </a>
  
                <a href="/category/projects" className="navbar-item">
                  Projects
                </a>
  
                <Link to="/contact" className="navbar-item">
                  Contact Us
                </Link>
              </div>
  
              <div className="navbar-end social"> 
                  <a href="https://www.facebook.com/futureartists" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={["fab", "facebook"]} style={{color:"#fff"}} size="lg" /></a>
                
                  <a href="https://twitter.com/futureartists" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={["fab", "twitter"]} style={{color:"#fff"}} size="lg" /></a>
               
                  <a href="https://www.youtube.com/user/TheFutureArtists" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={["fab", "youtube"]} style={{color:"#fff"}} size="lg" /></a>
                
                  <a href="http://www.dailymotion.com/futureartists" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={["fab", "dailymotion"]} style={{color:"#fff"}} size="lg" /></a>   
              </div>
            </div>
          </nav>
      )
    }
  }
  
export default Navbar;